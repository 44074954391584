
.seriesPage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    padding: 5rem;
    width: calc(100vw - 10rem);
    height: calc(100vh - 10rem);
    background-color: #60267b4b;
}

.seriesInfo {
    width: calc(30vw - 5rem);
    height:calc(100vh - 10rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.episodeListNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.seriesInfo img {
    width:20vw;
    height:20vw;
    border-radius: 10px;
}

.seriesDetails {
    width: calc(70vw - 5rem);
    height:calc(100vh - 10rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.episodeList{
    overflow-y: auto;
    max-height: 50vh;
}

.seriesDetailsContent {
    

}

.seriesDetailsContent img {
    width:7vw;
    height:7vw;
    border-radius: 10px;
}


.seriesDetailsContent p {
    padding-left: 10px;

}

.episodeItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin: 1rem 0; */
}


.episodeItemContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0.6rem 0;
}


.audioPlayer{
    width: 100%
}
/* Additional CSS for layout and styling */
