.player-container {
	position: absolute;
    left: 0;
    top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	background-color: #AAEEEE;
	border-radius: 1rem;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	@media screen and (max-width: 768px) and (min-height: 768px) {
		flex-direction: column;
	}
}

.sub-container {
	padding: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	@media screen and (max-width: 768px) and (max-height: 768px) {
		padding: 0px 40px 0px 40px;
	}
}

.time-control-container {
	margin-top: 5vh;
	width: 50%;
	display: flex;
	@media screen and (max-width: 768px) {
		width: 90%;
	}
}

.song-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.little-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.img {
	width: 14vw;
	border-radius: 0.5rem;
	@media screen and (max-width: 768px) {
		width: 30vw;
	}
	@media screen and (max-width: 768px) and (max-height: 768px) {
		width: 20vw;
	}
}

.h1 {
	padding: 3rem 1rem 1rem 1rem;
	@media screen and (max-width: 768px) and (max-height: 768px) {
		padding: 0.3rem 0.1rem 0.1rem 0.1rem;
		margin: 0px;
	}
}

.h2 {
	font-size: 1rem;
	@media screen and (max-width: 768px) and (max-height: 768px) {
		font-size: 1rem;
		margin: 0px;
	}
}

.track {
	background: lightblue;
	width: 100%;
	height: 1rem;
	position: relative;
	border-radius: 1rem;
	overflow: hidden;
}

.animate-track {
	background: rgb(204, 204, 204);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.input {
	width: 100%;
	-webkit-appearance: none;
	background: transparent;
	cursor: pointer;
	&:focus {
		outline: none;
		-webkit-appearance: none;
	}
	@media screen and (max-width: 768px) {
		&::-webkit-slider-thumb {
			height: 48px;
			width: 48px;
		}
	}
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 16px;
		width: 16px;
		background: transparent;
		border: none;
	}
	&::-moz-range-thumb {
		background: transparent;
		border: none;
	}
	&::-ms-thumb {
		background: transparent;
		border: none;
	}
}

.p {
	padding: 0 1rem 0 1rem;
	user-select: none;
}

.play-control-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	width: 30%;
	@media screen and (max-width: 768px) {
		width: 60%;
	}
}
