.extplayer-episode-container {
    /* margin-top: 10vh; */
    min-height: 50vh;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.extplayer-episode-img {
    width: 20%;
    border-radius: 20%;

    @media screen and (max-width: 768px) {
        margin-top: 5vh;
        width: 30%;
    }
}

.extplayer-episode-h1 {
    padding: 1rem 0.5rem .5rem .5rem;
}

.extplayer-episode-h2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
}

.extplayer-episode-h2 {
    font-size: 1rem;
    max-width: 80%;
    overflow-y: auto;
    max-height: 6rem;
    /* Adjust this value based on line height and desired number of lines */
    -webkit-line-clamp: 5;
    /* Number of lines to show */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;

    @media screen and (max-width: 768px) {
        -webkit-line-clamp: 4;
    }
}



@media screen and (max-height: 400px) {

    .extplayer-episode-img{
        height:50%;
        width:auto;
    }
    .extplayer-episode-container {
        flex-direction: row;
        height: 5vh;
    }

    

    .extplayer-episode-h2-container {
        display: none;
    }
}