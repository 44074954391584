.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #pagination-next-page, #pagination-previous-page, #pagination-first-page, #pagination-last-page {
    fill: rgba(255, 255, 255, 1);
  }

  
  .modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-close:hover {
    color: red;
  }
  
  .modal-content h2 {
    margin-bottom: 15px;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  