/* .topic-carousel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
  } */
  
  .topic-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px; /* Adjust as needed */
    cursor: pointer;
  }
  
  .topic-carousel .carousel-inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
  }
  
  .topic-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 80px;
    border-radius: 7px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    cursor:pointer;
    /* Add other styles for individual topics */
  }

  .topic-item:hover {
    background-color: #ffa358!important;
}

  /* make the block darker on hover */

    

  @media (max-width: 767px) {
    .topic-carousel .carousel-inner {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  
    .topic-item {
      width: 40vw;
      height: 40px;
      font-size: 12px;
    }

    .topic-item:hover {
      background-color: #ffa358!important;
    }

  }

  

/*   
  .episode-cover {
    position: relative;
    width: 200px;
    height: 200px;
  }
  
  
  .postImg{
    max-width: 200px;
    max-height: 200px;
  }
  
  .episode-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s;
  }
  
  .episode-cover:hover img {
    opacity: 0.7;
  } */
  