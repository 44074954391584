.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFE9F3;
  }
  
  .login-cardPack {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-cardPackResponsive {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 10px;
  }
  
  .login-card {
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .login-cardResponsive {
    width: 90%;
    margin-bottom: 10px;
  }
  
  .login-logo {
    border-radius: 10px;
    width: 200px;
    margin-bottom: 20px;
  }
  
  .login-rotatingText {
    flex: 2;
    padding: 20px;
    text-align: justify;
    margin: 10px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'tan_nimbus', 'sans-serif';
    color: #89023E;
    font-size: 15px;
    margin-bottom: 10px;
    text-transform: lowercase;
    font-weight: 20;
  }
  
  .login-imgSide {
    border-radius: 10px;
    width: 30vw;
    margin-bottom: 20px;
  }
  
  @media (max-width: 800px) {
    .login-cardPack {
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin-bottom: 10px;
    }
    .login-card {
      width: 90%;
      margin-bottom: 10px;
    }
  }
  

  @media (max-height: 767px) and (max-width: 767px) {
    .login-cardPack {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow-y: auto;
      height:calc(100vh - 50px);
    }

    .login-container {
      padding:10px;
      display: flex;
      justify-content: center;
      height: calc(100vh - 20px);
      overflow-y: auto;
      background-color: #FFE9F3;
    }
  
  }