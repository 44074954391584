.serie-page {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
}

.serie-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.serie-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 20px;
}

.serie-details {
    flex-grow: 1;
}

.serie-details h1 {
    margin: 0;
    color: #333;
}

.serie-details p {
    margin: 5px 0;
    color: #666;
}

.episode-list {
    margin-top: 20px;
}

.episode-item {
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 5px;
}

.episode-link {
    text-decoration: none;
    color: inherit;
}

.episode-link h3 {
    margin: 0;
    color: #333;
}

.episode-link p {
    color: #666;
}


.episode-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.episode-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5%;
}

.episode-info {
    flex-grow: 1;
}

.play-button-episode-list {
    background-color: #008cba;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.play-button-episode-list:hover {
    background-color: #005f73;
}


.delete-button{
    background-color: red;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.play-button-episode-list:hover {
    background-color: #730013;
}


.share-button{
  /* color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; */
  background-color: #0f3a63;
}


@media (max-width: 767px) {

    .serie-page {
        padding-bottom: 25px !important;
        margin-bottom: 25px !important;
    }

    h1 {
        font-size: 1.4em !important;
    }

    .serie-header {
        flex-direction: column;
        align-items: center;
    }

    .serie-image {
        margin-bottom: 20px;
        margin-right: 0px;
    }

    .episode-item {
        display: flex !important; 
        flex-direction: column !important; 
    }

}
