/* SupportButton.css */
.support-button {
    display: inline-block;
    padding: 8px 8px;
    background-color: #0099ff;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
}

.support-button:hover {
    background-color: #0056b3;
}
