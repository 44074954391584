.extplayer-libraryepisode-container {
	padding: 0 2rem 0 2rem;
	height: 100px;
	width: calc(100%-4rem);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.3s ease;
	background-color: white;
}

.extplayer-libraryepisode-container:hover {
	background-color: lightblue;
	transition: all 0.3s ease;
}

.extplayer-libraryepisode-container.active {
	background-color: #89023E;
	color:#FFE9F3;
}

.extplayer-libraryepisode-description {
	max-width: 100%;
	/* height: 100%; */
	display: flex;
	padding: 0px 0px 0px 20px ;

	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

}

.extplayer-libraryepisode-img {
	margin: 10px 0;
	height: 70px;
	border-radius: 7px;;
}

.extplayer-libraryepisode-h1 {
	padding-left: 1rem;
	font-size: 1rem;
}

.extplayer-libraryepisode-h2 {
	padding-left: 1rem;
	font-size: 0.7rem;
}
