.extplayer-serieplayer-app-container {
    transition: all 0.5s ease;
    margin-left: 0;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.extplayer-serieplayer-app-container.library-open {
    margin-left: 30rem;
}

@media screen and (max-width: 1000px) {
    .extplayer-serieplayer-app-container {
        height: calc(100vh - 150px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 0rem !important;
        width:100%;
    }
}

.extplayer-serieplayer-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.extplayer-serieplayer-spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #22a6b3;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.extplayer-serieplayer-loading-text {
    margin-top: 16px;
    font-size: 1.5rem;
    color: #555;
}
