.extplayer-player-container {
	min-height: 20vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.extplayer-player-timecontrol-container {
	margin-top: 5vh;
	width: 50%;
	display: flex;
	@media screen and (max-width: 768px) {
		width: 90%;
	}
}

.extplayer-player-track {
	background: lightblue;
	width: 100%;
	height: 1rem;
	position: relative;
	border-radius: 1rem;
	overflow: hidden;
	background: linear-gradient(to right, "#EF8EA9", "#ab417f");
}

.extplayer-player-animatetrack {
	background: rgb(204, 204, 204);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.extplayer-player-input {
	width: 100%;
	-webkit-appearance: none;
	background: transparent;
	cursor: pointer;
	&:focus {
		outline: none;
		-webkit-appearance: none;
	}
	@media screen and (max-width: 768px) {
		&::-webkit-slider-thumb {
			height: 48px;
			width: 48px;
		}
	}
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 16px;
		width: 16px;
		background: transparent;
		border: none;
	}
	&::-moz-range-thumb {
		background: transparent;
		border: none;
	}
	&::-ms-thumb {
		background: transparent;
		border: none;
	}
}

.extplayer-player-p {
	padding: 0 1rem 0 1rem;
	user-select: none;
}

.extplayer-player-playcontrol-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	width: 30%;
	@media screen and (max-width: 768px) {
		width: 60%;
	}
}
