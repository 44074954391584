.episode-page {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
}

.episode-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.episode-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 20px;
}

.episode-details {
    flex-grow: 1;
}

.episode-details h1 {
    margin: 0;
    color: #333;
}

.episode-details p {
    margin: 5px 0;
    color: #666;
}

.loading {
    text-align: center;
    font-size: 1.2em;
    color: #333;
}

.episode-player {
    display: flex;
    align-items: center;
    justify-content: center;
}

.episode-player button {
    /* background-color: #008cba; */
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.episode-player button:hover {
    background-color: #005f73;
}

.like-button{
    background-color: green;
}

.dislike-button{
    background-color: red;
}

.delete-button{
    background-color: red;
}

.report-button{
    background-color: black;
}


.share-button{
    background-color: #0f3a63;
}

.read-more-button{
    background-color: #476076;
    color: white;
    border: none;
    padding: 3px 7px;
    margin: 2px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 11px;
}


@media (max-width: 767px) {

    .episode-page {
        padding-bottom: 25px !important;
        margin-bottom: 25px !important;
    }

    h1 {
        font-size: 1.4em !important;
    }

    .episode-header {
        flex-direction: column;
        align-items: center;
    }

    .episode-image {
        margin-bottom: 20px;
        margin-right: 0px;
    }

}