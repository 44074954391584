.extplayer-song-container {
	margin-top: 0px;
	position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	display: flex;
	flex-direction: column;
}

.extplayer-song-img {
	width: 3rem;
	border-radius: 0.5rem;
	@media screen and (max-width: 768px) {
		width: 5rem;
	}
}

.extplayer-song-h1 {
	padding: 3rem 1rem 1rem 1rem;
}

.extplayer-song-h2 {
	font-size: 1rem;
}
