.language-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-bottom: 20px; */
}

.language-label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.language-select {
  width: 100%;
  padding: 2px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.language-select:focus {
  border-color: #007bff;
  outline: none;
}
