.extplayer-credit-container {
	width: calc(100% - 30px);
	position:fixed;
	bottom: 0px;
	left: 0px;
	user-select: none;
	/* position: fixed; */
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 15px 0px 15px;
	justify-content: space-between;;
	z-index: 12;
	/* padding: 15px; */
	height:40px;
	/* bottom: 0px;
	right: 0px; */
	background-color: #ffffff;
	box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
	color: rgb(155, 155, 155);
	font-size: 0.75rem;
}

.credit-bottom-right{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between
}


.extplayer-credit-link {
	color: rgb(155, 155, 155);
	text-decoration: none;
}

.extplayer-credit-endText{
	text-align: end;
	padding-left:10px;
}
.extplayer-credit-link:hover {
    text-decoration: underline;
}


.extplayer-credit-baseSelectorTitle {
	font-family: 'tan_nimbus', sans-serif;
	color: #89023E;
	font-size: 10px;
	/* margin-bottom: 10px; */
	text-transform: lowercase;
	font-weight: 10;
  }

  .extplayer-credit-playlistBtn{
	background: transparent;
	border: none;
	cursor: pointer;
	color:#89023E;
	border: 2px solid #89023E;
	padding: 0.2rem;
	transition: all 0.3s ease;	

  }

  .extplayer-credit-playlistBtn:hover {
	background: #89023E;
	color: white;
	text-decoration: none;
  }

  .extplayer-credit-playlistBtn:focus {
	outline: none;
  }

