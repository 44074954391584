.rdt_TableCol {
  width: 33% !important; /* Ensures that the column width is respected */
}

.rdt_TableCell {
  width: 33% !important; /* Ensures that the column width is respected */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipsis (...) when text overflows */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  cursor: pointer;
}


.kPRdhe {
  padding: 0px !important;
}

.rdt_TableRow {
  width: 100% !important;
  height:20px;
}

.rdt_TableHeadRow {
  width: 100% !important;
}

.jTPWeN {
  width: 100% !important;
}

.rdt_Table {
  width: 100% !important;
}

/* Tooltip styling */
.table-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
