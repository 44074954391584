/* Subscription Styles */

.container-payment {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eceff1; /* Light gray background */
    overflow-y: auto;
    flex-direction: column;
}

.subscription-card {
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.subscription-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
}

.subscription-card.selected {
    border: 2px solid #89023E;
    box-shadow: 0px 0px 20px 0px rgba(137, 2, 62, 0.6);
}

.subscription-detail {
    margin-top: 10px;
}



.title-box {
    font-weight: bold;
    margin-bottom: 5px;
}

.content-box {
    font-size: 14px;
}

.continue-button {
    background-color: #89023E;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.continue-button:hover {
    background-color: #3bc9db;
}

/* Base Styles for the Form */
.payment-form {
    flex: 1;
    font-family: Arial, sans-serif;
    width: 100%;
    padding: 20px;
    background-color: #f4f4f4;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow-y: auto;
}

/* Title Style */
.payment-form-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

/* Style for the Payment Element */
.PaymentElement {
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    flex-grow: 1;
}

/* Error Message Styles */
.payment-form .error {
    color: #ff6b6b;
    margin-bottom: 20px;
}

/* Loading State Styles */
.payment-form .loading {
    font-style: italic;
}

@media (max-width: 767px) {

    .payment-form {
        height: 100%;
        overflow-y: auto;
        width: 100%;
    }
}

.subscription-boxing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.subscription-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    color: rgb(109, 110, 120);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px var(--colorPrimary);
    /* background-color: #ffffff; */
    /* padding: 12px; */
    width: 100%;
    /* border: 1px solid rgb(230, 230, 230);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02); */
}

.first-box {
    margin-right: 10px;
}

.label-subscription {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: rgb(48, 49, 61);
}

.title-box {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.content-box {
    color: #89023E;
    font-size: 14px;
    font-weight: 900;
    margin: 0;
    font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}


.container-payment {
    width: 100%;
    height:100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eceff1; /* Light gray background */
    overflow-y: auto;
    flex-direction: column;
}

/* Subscription Selection Styles */
.subscription-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
}

.subscription-cards {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
}

.subscription-card {
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    width: 20vw;
    height: 65vh;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.subscription-card h3{
    color: #89023E;
    font-size: 1.9rem;
  font-family: 'tan_nimbus', sans-serif;
  text-transform: lowercase;
  font-weight: 30;
}

.subscription-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
}

.subscription-card.selected {
    border: 2px solid #89023E;
    box-shadow: 0px 0px 20px 0px rgba(137, 2, 62, 0.6);
}

.subscription-detail {
    margin-top: 10px;
}

.content-box {
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    width:90%;
    border-radius: 4px;
    margin-bottom: 10px;
}

.title-box {
    text-align: center;
    font-size: 20px !important;
    margin-bottom:20px !important;
    font-family: 'Lato', sans-serif;
    color: #ffffff;
    background-color: #FF7817 !important;
    text-transform: lowercase;
    font-weight: 20;
    font-weight: bold;
    margin-bottom: 5px;
}

.content-box {
    font-size: 14px;
}

.continue-button {
    background-color: #89023E;
    color: white;
    padding: 10px 15px;
    border: none;
    width:100%;
    border-radius: 4px;
    cursor: pointer;
    font-size: 22px;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.continue-button:hover {
    background-color: #3bc9db;
}

.container-payment-payment{
    padding:10px;
    height:calc(100% - 20px);
    display:flex;
    justify-content:center;
    align-items:center;
    width:70%;
}
/* Base Styles for the Form */
.payment-form {
    flex: 1;
    font-family: Arial, sans-serif;
    width: 90%;
    padding: 20px;
    background-color: #f4f4f4;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

/* Title Style */
.payment-form-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

/* Style for the Payment Element */
.PaymentElement {
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    flex-grow: 1;
}

/* Style for Input Fields - can be adjusted if using custom fields */
.payment-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style for the Submit Button */
.payment-form button {
    background-color: #89023E;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: background-color 0.3s;
}

.payment-form button:hover {
    background-color: #3bc9db;
}

/* Error Message Styles */
.payment-form .error {
    color: #ff6b6b;
    margin-bottom: 20px;
}

/* Loading State Styles */
.payment-form .loading {
    font-style: italic;
}

@media (max-width: 1250px) {

    .container-payment-payment{
        padding:0px;
        height:calc(100%);
        display:block;
        /* justify-content:center;
        align-items:center; */
        width:100%;
    }

    .container-payment{
        justify-content: flex-start;
        top:0px;
    }

    .subscription-selection{
        height:100%;
        width: calc( 100% - 40px);
        overflow-y: auto;
    }

    .subscription-cards {
        flex-direction: column;
    }

    .subscription-card{
        width: 90%;
        height: auto;
    }

    .payment-form {
        height:100%;
        overflow-y: auto;
        width: 90%;
    }
}

  
/* SubscriptionStyles.css */

button:disabled {
    background-color: grey;
    cursor: not-allowed;
  }

  

@media (max-width: 768px) {

    .container-payment-payment{
        padding:0px;
        height:calc(100%);
        display:block;
        /* justify-content:center;
        align-items:center; */
        width:100%;
    }

    .container-payment{
        justify-content: flex-start;
        top:0px;
    }

    .subscription-selection{
        height:calc(100% - 85px - 40px);
        width: calc( 100% - 40px);
        overflow-y: auto;
    }

    .subscription-cards {
        flex-direction: column;
    }

    .subscription-card{
        width: 90%;
        height: auto;
    }

    .payment-form {
        height:calc(100% - 85px - 40px);
        overflow-y: auto;
        width: 90%;
    }
}

