.extplayer-library-container {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 30rem;
    height: 100%;
    background-color: white;
    box-shadow: 2px 2px 50px rgb(204, 204, 204);
    user-select: none;
    overflow: scroll;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
    padding-top:20px;
}

.extplayer-library-container.active {
    transform: translateX(0%);
    opacity: 100;
}

.extplayer-library-container::-webkit-scrollbar {
    width: 5px;
}

.extplayer-library-container::-webkit-scrollbar-track {
    background: transparent;
}

.extplayer-library-container::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

@media screen and (max-width: 1000px) {
    .extplayer-library-container {
        width: 100%;
        z-index: 9;
        height:calc(100% - 40px); 
        margin-bottom: 40px;
    }
}

.extplayer-library-episodes-container {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.extplayer-library-header {
    padding: 1rem;
    text-align: center;
}

.extplayer-library-series-image {
    width: 30%;
    height: auto;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.extplayer-library-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.extplayer-library-desc {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
}

.extplayer-library-h1 {
    padding: 2rem;
}
