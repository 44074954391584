.public-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    height: calc(100vh - 80px); 
    overflow-y: auto; /* Enable scrolling for the entire container */
}

.profile-header {
    text-align: center;
    margin-bottom: 30px;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 20px;
}

.profile-name {
    font-size: 2em;
    margin-bottom: 10px;
}

.profile-description {
    font-size: 1.2em;
    color: #666;
}

.series-list {
    width: 100%;
    max-width: 800px;
}

.series-list h2 {
    text-align: center;
    margin-bottom: 20px;
}

.series-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.series-item:hover {
    background-color: #f0f0f0;
}

.series-image {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 20px;
}

.series-info {
    flex: 1;
}

.series-info h3 {
    margin: 0 0 10px 0;
    font-size: 1.5em;
}

.series-info p {
    margin: 0;
    color: #666;
}
