#generator {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* min-height: 80vh; */
  background-color: #f4f4f4;
  font-family: "Lato", sans-serif;
}

#generator input[type="text"] {
  width: 70%;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

#generator input[type="text"]:focus {
  border-color: #FF7817;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

#generator textarea {
  width: 60%;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

#generator .form-label {
  width: 70%;
  margin: 0 0 0px;
  padding-top: 15px;
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  /* overflow-x: hidden; */
  overflow-wrap: break-word;
}

#generator textarea:focus {
  border-color: #FF7817;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

#generator select {
  width: 70%;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #dadada;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 10px;
}

#generator .basic-multi-select {
  width: 70%;
  /* padding: 10px; */
  margin: 10px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #dadada;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 10px;
}


#generator button {
  /* color: #89023E; */
  font-family: 'tan_nimbus', sans-serif;
  text-transform: lowercase;
  width: 70%;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #89023E;
  color: white;
  cursor: pointer;
}


#generator select,
#generator input {
  display: block;
  box-sizing: border-box;
}

#generator #button-selector-generate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  flex-direction: row;
}

#generator .toggle-option {
  background-color: #ff8800;
  color: white;
}

#generator .active-toggle {
  background-color: rgb(217, 90, 0);
  color: white;
}

.selector-serie select {
  width: 100% !important;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #dadada;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 10px;
}

.selector-serie-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.series-select-container {
  display: flex;
  align-items: center;
  width: 70%;
}


.series-select {
  width: 70%;
}

#select-serie-btn {
  background-color: green !important;
  color: white !important;
  width: 40% !important;
}

#create-new-serie-btn {
  background-color: grey !important;
  color: white !important;
  width: 20% !important;
}


.option-cards-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
}

.option-card {
  width: 30%;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}


.option-card h3 {
  color: #89023E;
  font-family: 'tan_nimbus', sans-serif;
  text-transform: lowercase;
  font-weight: 20;
}

.option-card h3 svg {
  font-size: 20px;
}

#baseSelectorTitle {
  font-family: 'tan_nimbus', sans-serif;
  color: #89023E;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: lowercase;
  font-weight: 20;
}

#baseSelectorTitleLato {
  font-family: 'Lato', sans-serif;
  color: #89023E;
  font-size: 20px;
  margin-bottom: 10px;
  /* text-transform: lowercase; */
  font-weight: 400;
}

.create-series-container {
  margin-bottom: 20px;
  text-align: center;
}

#create-series-btn-generator {
  /* padding: 10px 20px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  height: 195px;
  width: 195px;
  border: none;
  background-color: #FFE9F3;
  color: #FF7817;
  font-family: 'tan_nimbus', sans-serif;
  border: solid 4px #FF7817;
  cursor: pointer;
  border-radius: 20px;
  text-transform: lowercase;
  font-weight: 20;
}

#create-series-btn-generator svg {
  font-size: 44px;
  margin-bottom: 20px
}

#create-series-btn-generator a {
  font-family: 'tan_nimbus', sans-serif;
  font-size: 17px;
  text-transform: lowercase;
  font-weight: 20;
}

.series-thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.serie-thumbnail {
  width: 120px;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.serie-thumbnail img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 5px;
}

.thumbnails-wrap {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}


@media (max-width: 767px) {

  .option-cards-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }

  .option-card {
    width: 90%;
    padding: 10px;
    margin: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }


  #create-series-btn-generator {
    /* padding: 10px 20px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    height: 145px;
    width: 145px;
    border: none;
    background-color: #FFE9F3;
    color: #FF7817;
    font-family: 'tan_nimbus', sans-serif;
    border: solid 4px #FF7817;
    cursor: pointer;
    border-radius: 20px;
    text-transform: lowercase;
    font-weight: 20;
  }


}

.form-generation-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.form-generation-switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.form-generation-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.form-generation-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.form-generation-switch input:checked + .form-generation-slider {
  background-color: #2196F3;
}

.form-generation-switch input:focus + .form-generation-slider {
  box-shadow: 0 0 1px #2196F3;
}

.form-generation-switch input:checked + .form-generation-slider:before {
  transform: translateX(26px);
}
