.extplayer-nav-container {
	/* position:fixed;
	top: 0;
	left: 0; */
	height:90px;

	min-height: 10vh;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

@media screen and (max-width: 1000px) {
	
	.extplayer-nav-container {
		position:fixed;
		height:90px;
		top: 0;
		left: 0;
		/* position: fixed; */
		z-index: 12;
		top: 0;
		left: 0;
		width: 100%;
	}
}

.extplayer-nav-title {
	transition: all 0.5s ease;
}

@media screen and (max-width: 1000px) {

	.extplayer-nav-container {
		justify-content: space-between;
		padding: 0 1.5rem 0 1.5rem;
		width: calc(100% - 3rem);
	}

	.extplayer-nav-title[data-library-status="true"] {
		visibility: hidden;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.extplayer-nav-title[data-library-status="false"] {
		visibility: visible;
		opacity: 100;
		transition: all 0.5s ease;
	}
}

.extplayer-nav-button {

	background: transparent;
	border: none;
	cursor: pointer;
	background-color: #ffffff;
	color:#89023E;
	border: 2px solid #89023E;
	padding: 0.5rem;
	transition: all 0.3s ease;
	
}

.extplayer-nav-button:hover {
	background: #89023E;
	color: white;
	
}

.extplayer-nav-button svg{
	margin-left: 0.5rem;
}


@media screen and (max-height: 400px) {
	
}