.conditions-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 100vh;
    background-color: #FFE9F3;
  }
  
  .conditions-cardPack {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .conditions-cardPackResponsive {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 10px;
  }
  
  .conditions-card {
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    max-width: 70vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 70vw;
  }
  
  .conditions-cardResponsive {
    width: 90%;
    margin-bottom: 10px;
  }
  
  .conditions-logo {
    border-radius: 10px;
    width: 200px;
    margin-bottom: 20px;
  }
  
  .conditions-rotatingText {
    flex: 2;
    padding: 20px;
    text-align: justify;
    margin: 10px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'tan_nimbus', 'sans-serif';
    color: #89023E;
    font-size: 15px;
    margin-bottom: 10px;
    text-transform: lowercase;
    font-weight: 20;
  }
  
  .conditions-imgSide {
    border-radius: 10px;
    width: 30vw;
    margin-bottom: 20px;
  }
  
  @media (max-width: 800px) {
    .conditions-cardPack {
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin-bottom: 10px;
    }
    .conditions-card {
      width: 90%;
      margin-bottom: 10px;
    }
  }
  